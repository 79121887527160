import axios from '@/common/js/request'

export function periodInsertOrUpdate(data) { // 安检周期配置新增或修改
    const periodInsertOrUpdate = axios({
        url: '/AaumSet/periodInsertOrUpdate',
        method: 'post',
        data
    })
    return periodInsertOrUpdate
}

export function periodList(params){ // 安检周期配置列表
    const periodList = axios({
        url: '/AaumSet/periodList',
        method: 'get',
        params
    })
    return periodList
}

export function userTypePeriodDropDown(params){ // 安检周期用户分类下拉（排除已配置）
    const userTypePeriodDropDown = axios({
        url: '/AaumSet/userTypePeriodDropDown',
        method: 'get',
        params
    })
    return userTypePeriodDropDown
}

export function dicdelete(params){ // 安检周期配置删除
    const dictDelete = axios({
        url: '/AaumSet/dictDelete',
        method: 'get',
        params
    })
    return dictDelete
}
export function periodDelete(params){ // 安检周期配置删除
    const periodDelete = axios({
        url: '/AaumSet/periodDelete',
        method: 'get',
        params
    })
    return periodDelete
}
/* ---------------2023/3/1 居民安检周期配置------ **/
export function periodPageList(params){ // 安检周期分页列表
    const periodPageList = axios({
        url: '/arumPeriod/periodPageList',
        method: 'get',
        params
    })
    return periodPageList
}
export function selectRepeat(params){ // 获取是否可重复
    const selectRepeat = axios({
        url: '/arumPeriod/selectRepeat',
        method: 'get',
        params
    })
    return selectRepeat
}
export function repeatSet(params){ // 是否可重复开关
    const repeatSet = axios({
        url: '/arumPeriod/repeatSet',
        method: 'get',
        params
    })
    return repeatSet
}
export function apPeriodDelete(params){ // 安检周期删除
    const apPeriodDelete = axios({
        url: '/arumPeriod/periodDelete',
        method: 'get',
        params
    })
    return apPeriodDelete
}
export function apPeriodInsertOrUpdate(data){ // 安检周期新增或修改
    const apPeriodInsertOrUpdate = axios({
        url: '/arumPeriod/periodInsertOrUpdate',
        method: 'post',
        data
    })
    return apPeriodInsertOrUpdate
}
//----------------次数频率配置------------------
export function frequencyList(params){ // 采集项分页列表
    const frequencyList = axios({
        url: '/AaumSet/frequencyList',
        method: 'get',
        params
    })
    return frequencyList
}
export function frequencySet(data){ // 采集频率配置设置
    const frequencySet = axios({
        url: '/AaumSet/frequencySet',
        method: 'post',
        data
    })
    return frequencySet
}
//----------------采集配置项--------------------
export function itemPageList(params){ // 采集项分页列表
    const itemPageList = axios({
        url: '/AaumSet/itemPageList',
        method: 'get',
        params
    })
    return itemPageList
}
export function itemGeneralStatusToggle(params){ // 采集项启用停用
    const itemGeneralStatusToggle = axios({
        url: '/AaumSet/itemGeneralStatusToggle',
        method: 'get',
        params
    })
    return itemGeneralStatusToggle
}
export function itemInsertOrUpdate(data){ // 采集项新增或修改
    const itemInsertOrUpdate = axios({
        url: '/AaumSet/itemInsertOrUpdate',
        method: 'post',
        data
    })
    return itemInsertOrUpdate
}
export function itemDetails2(params){ // 采集项查看详情2
    const itemDetails2 = axios({
        url: '/AaumSet/itemDetails2',
        method: 'get',
        params
    })
    return itemDetails2
}
export function templatePageList(params){ // 模板分页列表
    const templatePageList = axios({
        url: '/AaumSet/templatePageList',
        method: 'get',
        params
    })
    return templatePageList
}
export function templateGeneralStatusToggle(params){ // 模板启用停用
    const templateGeneralStatusToggle = axios({
        url: '/AaumSet/templateGeneralStatusToggle',
        method: 'get',
        params
    })
    return templateGeneralStatusToggle
}
export function itemList(data){ // 模板 - 采集项列表
    const itemList = axios({
        url: '/AaumSet/itemList',
        method: 'post',
        data
    })
    return itemList
}
export function templateInsertOrUpdate(data){ // 模板新增或修改
    const templateInsertOrUpdate = axios({
        url: '/AaumSet/templateInsertOrUpdate',
        method: 'post',
        data
    })
    return templateInsertOrUpdate
}
export function templateDetails(params){ // 模板查看详情
    const templateDetails = axios({
        url: '/AaumSet/templateDetails',
        method: 'get',
        params
    })
    return templateDetails
}
/******************** 巡线部分 ************************* */
export function periodListPolling(params){ // 安检周期配置列表
    const periodListPolling = axios({
        url: '/patrolSet/periodList',
        method: 'get',
        params
    })
    return periodListPolling
}
export function periodInsertOrPolling(data){ // 安检周期配置新增或修改
    const periodInsertOrPolling = axios({
        url: '/patrolSet/periodInsertOrUpdate',
        method: 'post',
        data
    })
    return periodInsertOrPolling
}
export function periodDeletePolling(params){ // 安检周期配置删除
    const periodDeletePolling = axios({
        url: '/patrolSet/periodDelete',
        method: 'get',
        params
    })
    return periodDeletePolling
}
/*********************巡线的采集项******************************** */
export function itemPageListPolling(params){ // 采集项分页列表
    const itemPageListPolling = axios({
        url: '/patrolSet/itemPageList',
        method: 'get',
        params
    })
    return itemPageListPolling
}
export function itemInsertOrUpdatePolling(data){ // 采集项新增或修改
    const itemInsertOrUpdatePolling = axios({
        url: '/patrolSet/itemInsertOrUpdate',
        method: 'post',
        data
    })
    return itemInsertOrUpdatePolling
}
export function itemDetails2Polling(params){ // 采集项查看详情2
    const itemDetails2Polling = axios({
        url: '/patrolSet/itemDetails2',
        method: 'get',
        params
    })
    return itemDetails2Polling
}
export function itemGeneralStatusTogglePolling(params){ // 采集项启用停用
    const itemGeneralStatusTogglePolling = axios({
        url: '/patrolSet/itemGeneralStatusToggle',
        method: 'get',
        params
    })
    return itemGeneralStatusTogglePolling
}
/************************巡线采集项模板*************************** */
export function templatePageListPolling(params){ // 模板分页列表
    const templatePageListPolling = axios({
        url: '/patrolSet/templatePageList',
        method: 'get',
        params
    })
    return templatePageListPolling
}
export function templateGeneralStatusTogglePolling(params){ // 模板启用停用
    const templateGeneralStatusTogglePolling = axios({
        url: '/patrolSet/templateGeneralStatusToggle',
        method: 'get',
        params
    })
    return templateGeneralStatusTogglePolling
}
export function templateDetailsPolling(params){ // 模板查看详情
    const templateDetailsPolling = axios({
        url: '/patrolSet/templateDetails',
        method: 'get',
        params
    })
    return templateDetailsPolling
}
export function templateInsertOrUpdatePolling(data){ // 模板新增或修改
    const templateInsertOrUpdatePolling = axios({
        url: '/patrolSet/templateInsertOrUpdate',
        method: 'post',
        data
    })
    return templateInsertOrUpdatePolling
}
export function itemListPolling(data){ // 模板 - 采集项列表
    const itemListPolling = axios({
        url: '/patrolSet/itemList',
        method: 'post',
        data
    })
    return itemListPolling
}
// 车辆分页查询
export function patrolCarLists(params){ 
    const patrolCarLists = axios({
        url: '/patrolCar/page',
        method: 'get',
        params
    })
    return patrolCarLists
}
// 新增车辆
export function addpatrolCar(data){ 
    const addpatrolCar = axios({
        url: '/patrolCar/save',
        method: 'post',
        data
    })
    return addpatrolCar
}
// 修改车辆
export function updatepatrolCar(data){ 
    const updatepatrolCar = axios({
        url: '/patrolCar/update',
        method: 'post',
        data
    })
    return updatepatrolCar
}
// 删除车辆
export function deletepatrolCar(params){ 
    const deletepatrolCar = axios({
        url: '/patrolCar/delete',
        method: 'get',
        params
    })
    return deletepatrolCar
}